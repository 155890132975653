import { Navigate } from "react-router-dom";
import Landing from "../components/landing";
import BasicDetails from "../components/basicdetail/routes/step-three";
import Document from "../components/document/routes";
import BasicFormStepOne from "../components/basicdetail/routes";
import BasicFormStepTwo from "../components/basicdetail/routes/step-two";

export const publicRoutes = [
  // { path: "/", element: <Landing /> },
  { path: "/basic-details", element: <BasicFormStepOne /> },
  { path: "/basic-details/step-one", element: <BasicFormStepTwo /> },
  { path: "/basic-details/step-two", element: <BasicDetails /> },
  { path: "/submit-documents", element: <Document /> },
  { path: "*", element: <Navigate replace to="/basic-details" /> },
];
