import styles from '../styles/style.module.css';
import blackLogo from "../../../assets/images/blackLogo.png"
import citylogo from "../../../assets/images/citylogo.png"
import steOne from "../../../assets/images/steOne.png"
import blkn from "../../../assets/images/blkn.png"
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function BasicFormStepOne() {
  const navigate = useNavigate()

  useEffect(() => {
    window.localStorage.removeItem("countryId")
  }, [])

  const handleNextStep = () => {
    window.localStorage.setItem("step_one", "stepOne")
    navigate('/basic-details/step-one')
  }

  return (
    <div>
      <div className={styles.bassoDtailFoem}>
        <div className={styles.getStartBk}>
          <div className={styles.stepHeader}>
            <a className='w-40' href='https://commbitz.com/'><span>
              <img src={blkn} alt="" />
            </span></a>
            <Link to='/' className='w-60'>
            <span className='d-flex citylogo'>
            <img className='w-50' src={blackLogo} alt="" />
              <img className='w-50' src={citylogo} alt="" />
              
            </span>
            </Link>

          </div>
          <div className={styles.stepMain}>
            <img src={steOne} alt="" />
            <h3>Welcome!</h3>
            <p>You are just few steps away to get
              <b>1GB Free data.</b></p>
            <button onClick={handleNextStep}>Get Started <i className="fas fa-chevron-right"></i></button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicFormStepOne
