import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/style.module.css';
import logo from '../../../assets/images/log.png';
import frdoen from '../../../assets/images/frdoen.png';
import blackLogo from "../../../assets/images/blackLogo.png"
import citylogo from "../../../assets/images/citylogo.png"
import comLogo from "../../../assets/images/comLogo.svg"
import calndr from '../../../assets/images/calndr.png';
import blkn from "../../../assets/images/blkn.png"
import { BasicDetailSchema } from '../validation';
import { useFormik } from 'formik';
import MainLoader from '../../mainLoader';
import PhoneInput, { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import { addBasicDetails, checkNiyoUserEmail, getCountries, sendOtp, verifyOtp } from '../api';
import { Link, useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css'
import useAuth from '../../../lib/useAuth';
import Layout from '../../Layout';
import VerifyOtpModal from '../../modal/Verifition.tsx';
import { validateEmail } from '../../../lib/customValidations';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDocuments } from '../../document/api';
import Congratulations from '../../modal/congratulation';

const BasicDetails: React.FC = () => {
    const [countriesList, setCountriesList] = useState([])
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const { loginUser, logout } = useAuth()
    const countryId = window.localStorage.getItem("countryId");
    const [otpId, setOTPID] = useState('');
    const [show, setShow] = useState("")
    const [timer, setTimer] = useState(0);
    const [isEmailVerified, setIsEmailVerified] = useState(false)
    const resendOtpHandle = useRef<HTMLAnchorElement>(null);
    const [verifOTPId, setVerifOTPId] = useState('');
    const [userPartnerInfoId, setUserPartnerInfoId] = useState('');
    const [showModal, setShowModal] = useState<boolean>(false);
    

    useEffect(() => {
        window.localStorage.removeItem("formSubmited")
        logout()
        if (!countryId) navigate('/basic-details/step-one')
    }, [])

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            countryCode: "",
            phoneNumber: "",
            travellingCountry: countryId,
            travellingDate: "",
            returnDate: "",
            handsetModelNo: "",
        },
        validationSchema: BasicDetailSchema,
        onSubmit: async (values, { setFieldError }) => {
            setLoader(true)
            // if (!isEmailVerified) {
            //     setLoader(false)
            //     return setFieldError('email', 'email is not verified!');
            // }
            if (!isPossiblePhoneNumber(values.phoneNumber) || !isValidPhoneNumber(values.phoneNumber)) {
                setFieldError('phoneNumber', 'Invalid phone number');
                setLoader(false)
                return;
            }


            const parsedNumber = parsePhoneNumber(values.phoneNumber);
            let BasicPayload: any = {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                countryCode: `+${parsedNumber?.countryCallingCode as string}`,
                phoneNumber: parsedNumber?.nationalNumber as string,
                travellingCountry: countryId,
                travellingDate: values.travellingDate,
                handsetModelNo: values.handsetModelNo,
                // otpId: verifOTPId,
                registrationType: 2
            };
            if (values.returnDate) BasicPayload.returnDate = values.returnDate;
            toast.promise(
                addBasicDetails(BasicPayload),
                {
                    pending: {
                        render() {
                            return 'Trying to save your informations...';
                        }
                    },
                    success: {
                        render({ data }: any) {
                            loginUser(data?.data?.accessToken)
                            window.localStorage.setItem("userPartnerInfoId", data?.data?.userPartnerInfoId)
                            setUserPartnerInfoId(data?.data?.userPartnerInfoId)
                            // navigate('/submit-documents')
                            setLoader(false)
                            return "Your informations Successful saved";
                        }
                    },
                    error: {
                        render({ data }: any) {
                            setLoader(false)
                            return data?.data?.message || "An error occurred";
                        }
                    }
                });
        },
    });

    useEffect(()=> {
        if(userPartnerInfoId) {
            addDocuments({userPartnerInfoId})
            setUserPartnerInfoId("")
            setShowModal(true)
        }
    }, [userPartnerInfoId])

    const today = new Date().toISOString().split('T')[0];

    const handleSendOtp = () => {
        const email: any = formik.values.email;
        if (validateEmail(email)) {
            formik.setErrors({ ...formik.errors, email: "" });
        } else if (formik.errors.email !== undefined || email === "") {
            return false;
        }
        setLoader(true)
        const otpPayload = {
            email,
            type: 3
        }
        // checkNiyoUserEmail(email).then((data) => {
        //     if (data?.statusCode === 200 && data?.message == "Success") {

        toast.promise(
            sendOtp(otpPayload),
            {
                pending: {
                    render() {
                        return 'Trying to send OTP';
                    }
                },
                success: {
                    render({ data }) {
                        setOTPID(data.data.otpId);
                        setShow("show");
                        setTimer(Date.now() + 30000);
                        setFieldForVerify("email")
                        setLoader(false)
                        return 'OTP sent successfully';
                    }
                },
                error: {
                    render({ data }: any) {
                        setLoader(false)
                        return data?.data?.message || "An error occurred";
                    }
                }
            });
        //     }
        // }).catch((data) => {
        //     setLoader(false)
        //     return toast.error(data?.data?.message || "Something went wrong!")
        // })


    }

    const otpVeriFy = (payload: any) => {
        setLoader(true)
        toast.promise(
            verifyOtp(payload),
            {
                pending: {
                    render() {
                        return 'Trying to verify OTP';
                    }
                },
                success: {
                    render({ data }: any) {
                        setIsEmailVerified(true)
                        setVerifOTPId(data?.data?.otpId)
                        setLoader(false)
                        setShow("")
                        return 'OTP verified!';
                    }
                },
                error: {
                    render({ data }: any) {
                        setLoader(false)
                        return data?.data?.message || "An error occurred";
                    }
                }
            });
    }

    const [fieldForVerify, setFieldForVerify] = useState('')

    const handleResendOTP = () => {

        if (resendOtpHandle.current) {
            resendOtpHandle.current.click()
        }
    }
    const ClosedModal = (newValue: any) => {
        setShow(newValue);
    };
    return (

        <div className={`${styles.bassoDtailFoem} ${styles.baseBnner}`}>
            {loader && <MainLoader />}
            <form onSubmit={formik.handleSubmit}>
                <div className={styles.stepHeader}>
                    <a className='w-40' href='https://commbitz.com/'><span>
                        <img src={blkn} alt="" />
                    </span></a>
                    <Link to='/' className='w-60'>
                        <span className='d-flex citylogo'>

                            <img className='w-50' src={blackLogo} alt="" />
                            <img className='w-50' src={citylogo} alt="" />
                        </span>
                    </Link>

                </div>

                <h4>Could you please provide<br /> more details about your trip?</h4>
                <div className="row">
                    <div className="col-md-6">
                        <div className={styles.formGroup}>
                            <label>First Name*</label>
                            <input
                                type="text"
                                name="firstName"
                                placeholder="Enter First Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                            />
                            {formik.touched.firstName && formik.errors.firstName && (
                                <div className={styles.error}>{formik.errors.firstName}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={styles.formGroup}>
                            <label>Last Name*</label>
                            <input
                                type="text"
                                name="lastName"
                                placeholder="Enter Last Name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                            />
                            {formik.touched.lastName && formik.errors.lastName && (
                                <div className={styles.error}>{formik.errors.lastName}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className={styles.formGroup}>
                            <label>Phone*</label>
                            <PhoneInput
                                international
                                defaultCountry="SG"
                                className={styles.phoneNumber}
                                placeholder="Enter your phone number here"
                                onChange={(value) => {
                                    formik.setFieldValue('phoneNumber', value)
                                }}
                                value={formik.values.phoneNumber}

                            />
                            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                                <div className={styles.error}>{formik.errors.phoneNumber}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className={styles.formGroup}>
                            <label>Email*
                                {/* <a ref={resendOtpHandle} onClick={!isEmailVerified ? () => handleSendOtp() : () => ""}> {isEmailVerified ? "Verified" : "verify"}</a> */}
                            </label>
                            <input
                                type="text"
                                name="email"
                                placeholder="Enter your email here"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email && (
                                <div className={styles.error}>{formik.errors.email}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className={styles.formGroup}>
                            <label>Handset Model No*</label>
                            <input
                                type="text"
                                name="handsetModelNo"
                                placeholder="Enter handset model number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.handsetModelNo}
                            />
                            {formik.touched.handsetModelNo && formik.errors.handsetModelNo && (
                                <div className={styles.error}>{formik.errors.handsetModelNo}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className={styles.formGroup}>
                            <label>Travelling Date*</label>
                            {/* <input
                                type="date"
                                name="travellingDate"
                                placeholder="Select Date"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.travellingDate}
                                min={today}
                            /> */}
                            <DatePicker
                                selected={formik.values.travellingDate ? new Date(formik.values.travellingDate) : null} // Ensure it's a Date object
                                onChange={(date) => formik.setFieldValue('travellingDate', date)} // Set the date
                                name="travellingDate"
                                placeholderText="Select Travelling Date"
                                onBlur={formik.handleBlur}
                                dateFormat="dd-M-yyyy" // Optional
                                value={formik.values.travellingDate}
                                minDate={new Date()}
                            />
                            <span><img src={calndr} alt="Calendar Icon" /></span>
                            {formik.touched.travellingDate && formik.errors.travellingDate && (
                                <div className={styles.error}>{formik.errors.travellingDate}</div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className={styles.formGroup}>
                            <input type="submit" value="Submit" />
                        </div>
                    </div>
                </div>
            </form>
            <VerifyOtpModal
                show={show}
                otpId={otpId}
                timer={timer}
                otpVeriFy={otpVeriFy}
                fieldForVerify={fieldForVerify}
                handleResendOTP={handleResendOTP}
                ClosedModal={ClosedModal}
            />
            <Congratulations show={showModal}/>
        </div>

    );
}

export default BasicDetails;
